body {
  background-color: rgba(200, 198, 198, 0.6);
}

/* 
Navbar Container: Styles the navigation bar container, employing flexbox for a space-between layout and a dark background color.
*/
.navBarContainer {
  display: flex;
  justify-content: space-between;
  background-color: #333;
}

.topnav {
  overflow: hidden;
}

/* 
Logo and Navigation Links: Defines styles for the logo, navigation links, and hover effects within the top navigation bar, ensuring a consistent and visually appealing design.
*/
.logo {
  color: rgb(238, 238, 238);
  font-size: 21px;
  padding-left: 20px;
  font-family: Georgia, serif;
}

.topnav a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 22px;
  text-decoration: none;
  font-size: 17px;
}

.topnav a:hover {
  background-color: #ddd;
  color: black;
}

.topnav .icon {
  display: none;
}

/* 
Mobile Responsive Styles: Introduces styles for a vertical navigation bar, popupContainer, 
and various components optimized for screens with a maximum width of 750 pixels.
*/
.popupContainer {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(63, 63, 63, 0.6);
}

.dialogStyle {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;
  background-color: rgba(118, 118, 118, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.boxStyle {
  background-color: #333;
  padding: 2px;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.mainContent {
  padding-top: 8px;
}

.popupHeader {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.content {
  font-size: 0.7rem;
  padding-bottom: 5px;
}

.closeButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 10px 25px;
  outline: none;
}

.verticalNav {
  overflow: hidden;
  background-color: #333;
}
.quicklinks a {
  display: flex;
  flex-direction: column;
  text-align: left;
  text-decoration: none;
}
.verticalNav a {
  display: flex;
  flex-direction: column;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.verticalNav a:hover {
  background-color: #ddd;
  color: black;
}

/* 
Grid Containers for Content: Defines a grid layout for the overall content, creating a two-column structure for better organization and readability.
*/
.overAllcontent {
  padding: 95px;
}

.container {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 50px;
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 30px;
}

.overview {
  padding: 2em;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.08);
}

.heading2 {
  color: #333;
  border-bottom: 2px solid #efefef;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.paragraph {
  color: #666;
  line-height: 1.6;
  text-align: justify;
}

/* 
Footer Styling: Styles the footer with a fixed position at the bottom, a dark background, and white text. The social media icons are displayed in a flex layout for a clean and centered appearance.
*/
.footer {
  background-color: #333;
  color: white;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  font-size: 15px;
}

.icons {
  display: flex;
  justify-content: center;
  padding-bottom: 5px;
}
.icons > span {
  padding-left: 15px;
}

/* 
Media Query for Mobile Responsiveness: Adjusts styles for mobile responsiveness, modifying the layout, padding, and font sizes to enhance the user experience on smaller screens.
*/
@media (max-width: 750px) {
  .topnav a {
    float: right;
  }

  .overAllcontent {
    padding: 10px;
  }

  .container {
    grid-template-columns: auto;
    grid-row-gap: 25px;
    margin: 25px;
  }

  .logo {
    transform: translateY(25%);
  }

  .heading {
    font-size: 25px;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }

  .overview {
    padding: 10px;
  }
}
